// JavaScript Document

// Scripts written by Jacob Bearce @ Weblinx, Inc.

const FIELDS = {
    "type":    document.getElementById("type"),
    "surface": document.getElementById("surface"),
    "length":  document.getElementById("length"),
    "width":   document.getElementById("width"),
    "height":  document.getElementById("height"),
    "lights":  document.getElementById("lights"),
};

const BUTTONS = {
    "refresh": document.getElementById("refresh"),
    "reset":   document.getElementById("reset"),
};

const TARGET = document.getElementById("gallons_required");

/**
 * Calculate the gallons requires
 *
 * @return {void}
 */
const CALCULATE_AREA = () => {
    const TYPE    = document.getElementById("type").value;
    const SURFACE = FIELDS.surface.value.split("_");
    const LENGTH  = parseFloat(FIELDS.length.value);
    const WIDTH   = parseFloat(FIELDS.width.value);
    const HEIGHT  = parseFloat(FIELDS.height.value);
    const LIGHTS  = parseFloat(FIELDS.lights.value);

    let total_area       = 0;
    let result = {};
    let output           = "";

    if (SURFACE[0] === "walls") {
        // make sure all values are non-zero
        if (LENGTH > 0 && WIDTH > 0 && HEIGHT > 0) {

            if (TYPE === "three_sided_open_face") {
                total_area = (((2 * LENGTH) + WIDTH) * HEIGHT) + (LENGTH * WIDTH);
            } else if (TYPE === "cross_draft") {
                total_area = (((2 * LENGTH) + (2 * WIDTH)) * HEIGHT) + (LENGTH * WIDTH);
            }

            if (SURFACE[1] === "new") {
                result = {
                    942: total_area / 100,
                    946: total_area / 45,
                    948: total_area / 77,
                    949: total_area / 85,
                };
            } else if (SURFACE[1] === "coated") {
                result = {
                    945: total_area / 29,
                };
            } else if (SURFACE[1] === "old") {
                result = {
                    920: total_area / 1000,
                    942: total_area / 100,
                    948: total_area / 77,
                };
            } else if (SURFACE[1] === "damaged") {
                result = {
                    920: total_area / 1000,
                    942: total_area / 100,
                    948: total_area / 77,
                };
            }
        }
    } else if (SURFACE[0] === "floors") {
        // make sure all values are non-zero
        if (LENGTH > 0 && WIDTH > 0) {
            total_area = LENGTH * WIDTH;

            if (SURFACE[1] === "new") {
                result = {
                    951: total_area / 250,
                    947: total_area / 25,
                };
            } else if (SURFACE[1] === "coated") {
                result = {
                    947: total_area / 25,
                };
            } else if (SURFACE[1] === "damaged") {
                result = {
                    951: total_area / 250,
                    947: total_area / 25,
                };
            }
        }
    } else if (SURFACE[0] === "lights") {
        // make sure all values are non-zero
        if (LENGTH > 0 && WIDTH > 0 && LIGHTS > 0) {
            total_area = LENGTH * WIDTH * LIGHTS;

            result = {
                945: total_area / 74,
            };
        }
    }

    // make sure the object isn't empty before returning it.
    if (Object.keys(result).length > 0 && result.constructor === Object) {
        output += `<table class='coating-calculator__text text text--table __light'><thead class='text__header'><tr class='text__row'><td class='text__cell'>${l10n["coating-calculator"].col1}</td><td class='text__cell'>${l10n["coating-calculator"].col2}</td></tr></thead><tbody class='text__body'>`;

        for (const LABEL in result) {
            output += `<tr class='text__row'><td class='text__cell'>${LABEL}</td><td class='text__cell'>${Math.ceil(result[LABEL])}</td></tr>`;
        }

        output += "</tbody></table>";
    } else {
        output += `<p class='coating-calculator__text text __light __textcenter'>${l10n["coating-calculator"].error}</p>`;
    }

    // print the output
    TARGET.innerHTML = output;
};

/**
 * Reset a given field, optionally disabling it and hiding it
 *
 * @param {object} field
 * @param {boolean} disable
 * @param {boolean} hide
 *
 * @return {void}
 */
const RESET_FIELD = (field, disable = true, hide = false) => {
    field.value = "";

    if (disable) {
        field.setAttribute("disabled", "disabled");
        field.classList.add("input--disabled");
    }

    if (hide) {
        field.parentElement.classList.add("__none");
    }
};

/**
 * Enable a given field
 *
 * @param {object} field
 *
 * @return {void}
 */
const ENABLE_FIELD = (field) => {
    field.removeAttribute("disabled");
    field.classList.remove("input--disabled");
    field.parentElement.classList.remove("__none");
};

/**
 * Reset all inputs and the output
 *
 * @return {void}
 */
const RESET_FORM = () => {
    for (const FIELD in FIELDS) {
        if (FIELD === "type") {
            RESET_FIELD(FIELDS[FIELD], false);
        } else if (FIELD === "lights") {
            RESET_FIELD(FIELDS[FIELD], true, true);
        } else {
            RESET_FIELD(FIELDS[FIELD]);
        }
    }

    // clear the output
    TARGET.innerHTML = "";
};

/**
 * Initialize the calculator
 *
 * @return {void}
 */
const INIT = () => {
    // enable or disable surface & dimension fields based on selection of type
    FIELDS.type.addEventListener("change", () => {
        const TYPE = FIELDS.type.value;

        if (TYPE !== "") {
            // enable the "surface" field
            ENABLE_FIELD(FIELDS.surface);
        } else {
            // disable all but the first field
            RESET_FORM();
        }
    }, { passive: true });

    // enable or disable dimension fields based on selection of surface
    FIELDS.surface.addEventListener("change", () => {
        const SURFACE = FIELDS.surface.value.split("_");

        if (SURFACE[0] !== "") {
            // enable the dimensions fields
            ENABLE_FIELD(FIELDS.length);
            ENABLE_FIELD(FIELDS.width);
            ENABLE_FIELD(FIELDS.height);
            ENABLE_FIELD(FIELDS.lights);
        } else {
            // disable all but the first field
            RESET_FORM();
        }
    }, { passive: true });

    // show or hide dimensions fields based on selection of surface
    FIELDS.surface.addEventListener("change", () => {
        const SURFACE = FIELDS.surface.value.split("_");

        if (SURFACE[0] === "walls") {
            // disable the "lights" field
            RESET_FIELD(FIELDS.lights, true, true);

            // enable the "height" field
            ENABLE_FIELD(FIELDS.height);
        } else if (SURFACE[0] === "floors") {
            // disable the "lights" field
            RESET_FIELD(FIELDS.lights, true, true);

            // disable the "height" field
            RESET_FIELD(FIELDS.height, true, true);
        } else if (SURFACE[0] === "lights") {
            // disable the "height" field
            RESET_FIELD(FIELDS.height, true, true);

            // enable the "lights" field
            ENABLE_FIELD(FIELDS.lights);
        }
    }, { passive: true });

    // calculate when refresh button is pushed
    BUTTONS.refresh.addEventListener("click", (e) => {
        e.preventDefault();
        CALCULATE_AREA();
    }, { passive: false });

    // reset when reset button is pushed
    BUTTONS.reset.addEventListener("click", (e) => {
        e.preventDefault();
        RESET_FORM();
    }, { passive: false });
};

/**
 * Verify that all fields exist before initializing
 */

let initialize = TARGET ? true : false;

if (initialize === true) {
    for (const FIELD in FIELDS) {
        if (!FIELDS[FIELD]) {
            initialize = false; break;
        }
    }
}

if (initialize === true) {
    for (const BUTTON in BUTTONS) {
        if (!BUTTONS[BUTTON]) {
            initialize = false; break;
        }
    }
}

if (initialize === true) {
    INIT();
}
